<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M14.052 28.763a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5v-.95c0-1.227 1.191-2.398 2.431-2.398h8.69c1.24 0 2.43 1.171 2.43 2.399v.949zm-1-.95c0-.67-.74-1.398-1.431-1.398H2.93c-.691 0-1.431.727-1.431 1.399v.449h11.552v-.45z"
    />
    <path
      d="M12.12 25.915a.5.5 0 0 1-.5.5H2.932a.5.5 0 0 1-.5-.5v-.949c0-.753.709-1.45 1.466-1.45h6.758c.757 0 1.466.697 1.466 1.45v.95zm-1-.949c0-.196-.256-.45-.465-.45H3.897c-.209 0-.466.254-.466.45v.45h7.69v-.45zM20.178 8.474a.5.5 0 0 1 0 .713l-6.276 6.17a.5.5 0 0 1-.7 0l-4.346-4.272a.5.5 0 0 1 0-.713l6.276-6.17a.5.5 0 0 1 .701 0l4.345 4.272zM15.483 5.26L9.92 10.73l3.632 3.57 5.562-5.468-3.631-3.57z"
    />
    <path
      d="M16.34 12.272l12.31 12.102a.5.5 0 1 0 .7-.714L17.04 11.56a.5.5 0 0 0-.7.713zM9.557 10.372l-.482-.474c-.878-.863-2.136-.726-2.994.118-.863.848-1.004 2.1-.121 2.968l5.31 5.22c.878.863 2.136.725 2.994-.119.863-.848 1.004-2.099.121-2.967l-.483-.475a.5.5 0 0 0-.7.714l.482.474c.444.437.374 1.054-.12 1.541-.5.491-1.143.561-1.593.119l-5.31-5.22c-.444-.437-.375-1.055.12-1.542.5-.49 1.143-.56 1.593-.118l.482.474a.5.5 0 0 0 .701-.713zM19.477 9.187l.483.475c.877.862 2.135.725 2.994-.119.863-.848 1.004-2.1.12-2.967l-5.31-5.22c-.877-.863-2.135-.726-2.994.118-.862.848-1.004 2.099-.12 2.967l.482.475a.5.5 0 1 0 .701-.713l-.482-.475c-.445-.437-.375-1.054.12-1.54.5-.492 1.142-.562 1.592-.12l5.31 5.22c.445.438.375 1.055-.12 1.542-.5.49-1.142.56-1.592.119l-.483-.475a.5.5 0 0 0-.7.713z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
